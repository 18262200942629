
.chat-message {
  display: flex;
  margin-top: 12px;
  margin-bottom: 1px;
  margin-right: 2rem;

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }

  .message-bubble {
    background: #fff;
    padding: 8px 16px 10px 16px;
    border-radius: 3px;
    font-size: 14px;

    .content {
      color: #333;
    }
  }

  &.show-avatar {
    .message-bubble {
      margin-left: 16px;
    }
  }

  &.me.show-avatar {
    .message-bubble {
      margin-right: 16px;
    }
  }

  &.me {
    margin-right: 0;
    margin-left: 2rem;
    display: flex;
    flex-direction: row-reverse;

    .message-bubble {
      background: rgb(229, 229, 241);
    }

  }

  .meta {
    font-size: 12px;
    display: flex;

    .user {
      color: rgb(72, 70, 68);
      margin-right: 6px;
    }

    .time {
      color: rgb(96, 94, 92);
    }
  }
}
