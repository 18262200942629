
.session-item {
  padding: 5px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(.active):hover {
    background: rgb(250, 250, 250);
  }

  &.active {
    background: #fff !important;
  }

  &.emphasis {
    font-weight: bold;
  }

  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    margin-right: 8px;
  }

  .content {
    flex: 1;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .time {
    font-size: 12px;
  }

  .latest-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 280px;
  }
}