.left-rail {
  position: relative;
  height: 100vh;
  background: #f0f0f0;
  width: 360px;

  &::before {
    background: linear-gradient(to right, transparent, #bebebe);
    content: '';
    height: 100%;
    opacity: .2;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: .6rem;
    z-index: 2;
  }

  .rail-header {
    display: flex;
    height: 60px;
    align-items: center;
    position: relative;
    color: #333;

    &::after {
      background: #e0e0e0;
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-left: 18px;
    }
  }
}

.left-rail .scrollbar-container {
  height: calc(100% - 60px);
}

.workspace {
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 14px;
  background-color: #f8f8f8;

  .chat-window-inner {
    height: 100vh;
  }

  .chat-staff {
    position: relative;
    box-shadow: none;
    background: #f5f5f5;
    height: 60px;
    box-sizing: border-box;

    &::after {
      content: '';
      background: #ebebeb;
      bottom: 0;
      box-shadow: none;
      height: 1px;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
}